import AuthMixin from '@/shared/mixins/auth.mixin'
import DashboardMixin from '@/shared/mixins/dashboard.mixin'
import ExamChart from '@/components/medico-home/exam-chart/index.vue'
import { mapActions } from 'vuex'

export default {
  name: 'medico-home',
  components: {
    ExamChart
  },
  mixins: [
    AuthMixin,
    DashboardMixin
  ],
  props: [],
  data () {
    return {
      pendingExamsCount: null,
      patientsCount: null
    }
  },
  computed: {
    doctorName () {
      return `${this.$i18n.t('doctor_abbreviation') + this.getUser().fullname}`
    },
    dashboard () {
      return [
        {
          name: this.$i18n.tc('multiple.pending_exam', 2),
          number: this.pendingExamsCount,
          route: '/exames-pendentes'
        },
        {
          name: this.$i18n.tc('multiple.patient', 2),
          number: this.patientsCount,
          route: '/pacientes'
        },
        {
          name: this.$i18n.tc('multiple.saved_exam', 2),
          disabled: true
        },
        {
          name: this.$i18n.tc('multiple.record', 2),
          disabled: true
        },
        {
          name: 'exam-chart',
          manual: true
        }
      ]
    }
  },
  mounted () {
    this.loadInformation()
  },
  methods: {
    ...mapActions([
      'setCurrentTab'
    ]),
    loadInformation () {
      this.fetchPendingExams()
      this.fetchPatients()
    },
    async fetchPendingExams () {
      this.pendingExamsCount = await this.getPendingExamsCount()
    },
    async fetchPatients () {
      this.patientsCount = await this.getPatientsCount()
    },
    changeTab (route) {
      this.setCurrentTab(route)
    }
  }
}
