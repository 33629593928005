import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from '@/shared/services/auth.service'
import routes from './routes'
import { requestGet } from '@/shared/services/interceptor.service'

Vue.use(VueRouter)

const authService = AuthService()
const router = new VueRouter({ mode: 'history', routes })

router.beforeEach(async (to, from, next) => {
  if (!to.name) {
    return next('/')
  }
  const roleName = authService.getFirstRole()

  if (to.name === 'processamento-arquivos') {
    if (roleName === 'source') {
      const allowPreOrder = await requestGet('/core/ExamSource/DisplayOptions', { useSelectedSource: true })
      return allowPreOrder.data.data.preOrder ? next() : next('/exames')
    }

    return next('/exames')
  }

  if (to.meta.roles && to.meta.roles.indexOf(roleName) === -1) {
    return next('/')
  }
  if (to.meta.requiresAuth && !authService.isAuthenticated()) {
    return next('/')
  }
  if (roleName === 'physician' && to.path !== '/' && to.path !== '/medico/validate' && sessionStorage.getItem('isValidatedPhysician') === null) {
    return next('/medico/validate')
  }

  if (roleName === 'physician' && authService.getOnlyReports() && to.meta.needsManagerPhysician) {
    return next('/medico/laudar')
  }

  if (roleName === 'admin' || (roleName === 'source')) {
    return next()
  }

  next()
})

export default router
