/* eslint-disable prefer-promise-reject-errors */
import LocalStorageMixin from '@/shared/mixins/local-storage.mixin'
import axios from 'axios'

let unexpectedErrorShown = false
let sessionExpiredErrorShown = false

const mixin = {
  mixins: [
    LocalStorageMixin
  ],
  data () {
    return {
      client: null,
      timeout: 60000,
      bypass: false
    }
  },
  beforeMount () {
    this.client = axios.create({
      baseURL: import.meta.env.VITE_URL_API,
      timeout: this.timeout,
      headers: {
        'Accept-Language': this.$i18n.locale
      }
    })

    this.client.interceptors.request.use((config) => {
      if (config.useSelectedSource) {
        delete config.useSelectedSource
        const selectedSource = JSON.parse(localStorage.getItem('selectedSource'))
        if (selectedSource) {
          config.params = {...config.params, sourceId: selectedSource.id}
        }
      }

      return config
    })

    this.client.interceptors.request.use((config) => {
      const user = this.getFromLocalStorage('user')
      const token = user ? user.token : null
      const refreshToken = user ? user.refreshToken : null

      if (config.url.indexOf('Token') === -1 && token) {
        if (refreshToken) {
          config.headers.refreshToken = refreshToken
        }
        config.headers.Authorization = token
      }

      return config
    })

    this.client.interceptors.response.use((response) => {
      if (response.headers.authorization) {
        const user = this.getFromLocalStorage('user')
        user.token = response.headers.authorization
        user.refreshToken = response.headers.refreshtoken
        this.updateFromLocalStorage('user', user)
        this.client.interceptors.request.use((config) => {
          config.headers.common.Authorization = response.headers.authorization
          config.headers.common.refreshToken = response.headers.refreshtoken
          return config
        })
      }

      return response
    }, (error) => {
      const response = error.response

      if (response && response.status === 401) {
        if (!sessionExpiredErrorShown) {
          this.showToast(this.$i18n.t('error.session_expired'))
          sessionExpiredErrorShown = true
          this.removeFromLocalStorage('user')
          this.$router.push('/')
        }
        this.$root.$emit('hideLoading')
        return Promise.reject()
      }

      if (this.bypass === true) {
        return error
      }

      if (!response) {
        if (import.meta.env.VITE_NODE_ENV !== 'testing') {
          if (!unexpectedErrorShown) {
            this.showToast(this.$i18n.t('error.unexpected_error_occurred'))
            unexpectedErrorShown = true
            setTimeout(() => {
              unexpectedErrorShown = false
            }, 5000)
          }
        } else {
          this.showToast(error)
        }
        this.$root.$emit('hideLoading')
        return Promise.reject()
      }      

      if (response.data.message) {
        this.showToast(response.data.message)
        this.$root.$emit('hideLoading')
        return Promise.reject()
      }

      const errors = response.data.errors
      if (errors) {
        if (Array.isArray(errors)) {
          errors.forEach((err) => {
            this.showToast(err.message)
          })
        } else {
          this.showToast(errors)
        }
        this.$root.$emit('hideLoading')
        return Promise.reject()
      }

      if (import.meta.env.VITE_NODE_ENV !== 'testing') {
        this.showToast(this.$i18n.t('error.unexpected_error_occurred'))
      } else {
        this.showToast(error)
      }
      this.$root.$emit('hideLoading')
      return Promise.reject()
    })
  },
  methods: {
    requestGet (url, config = {}) {
      return new Promise(async (resolve) => {
        const res = await this.client.get(url, config)
        resolve(res.data)
      })
    },
    requestGetBlob (url, type = 'application/pdf') {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.client.get(url, { responseType: 'blob' })
          const blob = new Blob([res.data], { type })
          resolve(window.URL.createObjectURL(blob))
        } catch (error) {
          reject(error)
        }
      })
    },
    requestPatch(url, data) {
      return this.client.patch(url, data)
    },
    requestPost (url, data) {
      return this.client.post(url, data)
    },
    requestPut (url, data, config = {}) {
      return this.client.put(url, data, config)
    },
    requestDelete (url, data) {
      return this.client.delete(url, data)
    },
    getClient () {
      return this.client
    },
    showToast (message) {
      this.$toasted.show(message, {
        icon: 'warning',
        duration: 5000,
        action: {
          icon: 'close',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        },
        className: 'toast'
      })
    }
  }
}

export default mixin