import routes from '@/router/routes'
import AuthMixin from '@/shared/mixins/auth.mixin'

export default {
  name: 'login',
  components: {},
  mixins: [
    AuthMixin
  ],
  data () {
    return {
      logo: '/assets/img/logo.png',
      authRoute: import.meta.env.VITE_AUTH_ROUTE,
      urlApi: import.meta.env.VITE_URL_API,
      routes,
      recaptchaPublicKey: import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY
    }
  },
  mounted () {
    localStorage.removeItem("selectedSource")
    const role = this.getFirstRole()
    if (role) {
      this.$router.push(`/${this.getHomePage()}`)
    }
    this.$root.$emit('hideLoading')
  }
}
