/* eslint-disable no-console */
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import AnalystReportMixin from '@/shared/mixins/analyst-report.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import CONSTANTS from '@/shared/constants/constants'
import imgMedicalReport from '@/assets/img/medical-report.png'
import imgSendInformation from '@/assets/img/send-information.png'
import imgReportTimeout from '@/assets/img/report-timeout.png'
import imgReceiveInformation from '@/assets/img/receive-information.png'
import imgReceiveRectification from '@/assets/img/retification-warning.png'
import { mapActions } from 'vuex'

export default {
  name: 'feedback',
  mixins: [
    MedicalReportMixin,
    AnalystReportMixin,
    AuthMixin
  ],
  data () {
    return {
      newExamInterval: 0,
      type: '',
      message: '',
      imgFeedback: '',
      instructionText: '',
      seconds: 10,
      timeRemaining: 0,
      monitor: Object,
      interval: Object,
      showDialog: false,
      hasCountDown: true,
      hasUpcomingExams: true,
      hasNewExamMonitor: false,
      needAlertPermission: false,
      silentAlertPermission: false,
      messageStopReport: this.$i18n.tc('feedback.stop_report', 1),
      imgMedicalReport,
      imgSendInformation,
      imgReportTimeout,
      imgReceiveInformation,
      imgReceiveRectification,
      showTokenButton: false,
    }
  },

  beforeDestroy () {
    this.changeTitleInterval(false)
    this.stopCountDown()
    this.stopNewExamMonitor()
  },
  mounted () {
    if (this.isAnalyst()) {
      this.messageStopReport = this.$i18n.tc('feedback.stop_report', 2)
    }
    this.$root.$on('showFeedbackPopup', () => {
      this.showFeedback(true, 'stillReporting')
    })
  },
  methods: {
    ...mapActions(['changeTitleInterval']),
    isAnalyst () {
      const role = this.getFirstRole()
      return role === CONSTANTS.ROLES.ANALYST
    },
    isWarnMessage () {
      return this.type === 'warnMessage' || this.type === 'rectificationMessage'
    },
    showFeedback (hasUpcomingExams, type) {
      this.type = type
      this.showDialog = true
      this.hasUpcomingExams = hasUpcomingExams
      this.instructionText = ''
      this.message = ''
      this.checkCertificateAndToken()

      console.log(type)

      switch (type) {
        case 'warnMessage':
          this.imgFeedback = this.imgReceiveInformation
          this.message = this.$i18n.t('feedback.exam_new_info')
          this.instructionText = this.$i18n.t('feedback.exam_has_new_info_verify_history')
          this.hasCountDown = false
          break

        case 'rectificationMessage':
          this.imgFeedback = this.imgReceiveRectification
          this.message = this.$i18n.t('feedback.exam_new_rectification')
          this.instructionText = this.$i18n.t('feedback.exam_has_new_rectification')
          this.hasCountDown = false
          break

        case 'unassign':
          this.imgFeedback = this.imgReportTimeout
          this.instructionText = this.$i18n.t('feedback.time_to_report_exceeded')
          this.hasCountDown = false
          break
        case 'sendInformation':
          this.imgFeedback = this.imgSendInformation
          this.message = this.$i18n.t('feedback.message_sent_sucessfully')
          this.instructionText = this.$i18n.t('feedback.when_requesting_source_answer')
          break

        case 'dontHaveExams':
          this.imgFeedback = this.imgMedicalReport
          this.instructionText = this.$i18n.t('feedback.want_to_be_notified_when_new_exams')
          this.needAlertPermission = true
          break

        case 'notifyNewExam':
          this.imgFeedback = this.imgMedicalReport
          this.instructionText = this.$i18n.t('feedback.we_inform_when_new_exams_arrive')
          this.hasNewExamMonitor = true
          this.silentAlertPermission = true
          this.needAlertPermission = false
          this.newExamMonitor()
          this.changeTitleInterval(false)
          break

        case 'newExamArrived':
          this.imgFeedback = this.imgMedicalReport
          this.instructionText = this.$i18n.t('feedback.new_exam_arrived')
          this.hasNewExamMonitor = false
          this.silentAlertPermission = false
          this.changeTitleInterval(true)
          break

        case 'stillReporting':
          this.imgFeedback = this.imgReportTimeout
          this.instructionText = this.$i18n.t('feedback.still_reporting')
          this.hasCountDown = false
          break

        default:
          this.imgFeedback = this.imgMedicalReport
          this.instructionText = this.$i18n.t('feedback.sucessfully_sent')
          this.changeTitleInterval(false)
          break
      }

      this.countDown()
      this.checkIfShowNotify()
    },
    ok () {
      this.showDialog = false
    },
    continueReporting () {
      this.showDialog = false
      this.feedbackShown = false
      this.$root.$emit('refreshCountdownEvent')
    },
    toggleAlertPermission () {
      this.silentAlertPermission = !this.silentAlertPermission
    },
    stopCountDown () {
      clearInterval(this.interval)
    },
    stopNewExamMonitor () {
      clearInterval(this.monitor)
    },
    stop () {
      const role = this.getFirstRole()
      const user = this.getUser()
      if (role === 'physician' && user.physician && !user.physician.onlyReports) {
        this.$router.push(`/${this.getHomePage()}`)
      } else {
        this.logoutUser()
      }
    },
    next () {
      this.showDialog = false
      this.stopCountDown()
      this.$emit('next')
    },
    countDown () {
      this.timeRemaining = this.seconds
      if (!this.hasUpcomingExams || !this.hasCountDown) {
        return
      }

      this.interval = setInterval(() => {
        this.timeRemaining--
        if (this.timeRemaining <= 0) {
          this.next()
        }
      }, 1000)
    },
    getClassButton () {
      if (this.hasUpcomingExams) {
        return 'button-next-enabled'
      }
      return 'button-next-disabled'
    },
    checkIfShowNotify () {
      if (!this.hasUpcomingExams && !this.hasNewExamMonitor && !this.needAlertPermission) {
        this.redirectToAlertOnNewExam()
      }
    },
    showNotifyNewExam () {
      this.showFeedback(this.hasUpcomingExams, 'notifyNewExam')
    },
    redirectToAlertOnNewExam () {
      setTimeout(() => {
        this.showNotifyNewExam()
      }, 5000)
    },
    newExamMonitor () {
      if (this.hasUpcomingExams || !this.hasNewExamMonitor) {
        return
      }

      this.monitor = setInterval(() => this.checkForNewExams(), 60000)
    },
    async checkForNewExams () {
      const { data: hasNewExam } = this.isAnalyst()
        ? await this.analystHasMoreExams() : await this.hasMoreExams()
      if (hasNewExam) {
        const notificationAlert = new Audio('/assets/sound/notification-sound.mp3')
        notificationAlert.muted = !this.silentAlertPermission
        notificationAlert.play()
        this.showFeedback(hasNewExam, 'newExamArrived')
        this.stopNewExamMonitor()
      }
    },
    checkCertificateAndToken() {
      this.getValidateCertificate().then(response => {
        const { cert, token, tokenExpiration } = response
        const expirationDate = new Date(tokenExpiration)
        const currentDate = new Date()
        const timeDifference = expirationDate.getTime() - currentDate.getTime()
        const timeRemainingHours = timeDifference / (1000 * 60 * 60)
  
        this.showTokenButton = cert && token && timeRemainingHours < 24
      })
    },
    redirectToValidation() {
      window.open('/medico/validate')
    }
  }
}
