import moment from 'moment'

export default {
  name: 'token-countdown',
  props: {
    token: {
      type: Boolean,
      required: true
    },
    tokenExpiration: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      timeRemaining: ''
    }
  },
  mounted() {
    if (this.token) {
      this.calculateTimeRemaining()
      this.interval = setInterval(this.calculateTimeRemaining, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    calculateTimeRemaining() {
      const expiration = moment(this.tokenExpiration)
      const now = moment()
      const duration = moment.duration(expiration.diff(now))

      this.timeRemaining = `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`

      if (duration.asSeconds() <= 0) {
        clearInterval(this.interval)
        this.timeRemaining = this.$t('token.expired_token')
      }
    }
  }
}
