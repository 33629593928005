import ExamMixin from '@/shared/mixins/exam.mixin'
import CONSTANTS from '@/shared/constants/constants'
import FileInfo from '@/shared/mixins/file-info.mixin'
import ExamInformationMixin from '@/shared/mixins/exam-information.mixin'
import ExamFileMixin from '@/shared/mixins/exam-file.mixin'

export default {
  name: 'exam-details-file-upload',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  mixins: [
    ExamMixin,
    FileInfo,
    ExamInformationMixin,
    ExamFileMixin
  ],
  data () {
    return {
      attachment: '',
      STATUS: CONSTANTS.STATUS,
      fileList: []
    }
  },
  computed: {
    examFiles () {
      const examFiles = this.details.examFiles
      if (!examFiles || !examFiles.length) {
        return []
      }

      return examFiles.map((file, index) => {
        let title = `${this.$i18n.t('document')} ${(index + 1)}`
        let extension = file.extension
        if (extension) {
          extension = ` (${extension})`
          title += extension
        }

        return title
      })
    }
  },
  methods: {
    showAttachFile () {
      if (this.details.status.id === this.STATUS.AGUARDANDO_OBSERVACAO) {
        return true
      }
    },
    async showFile (index) {
      const file = this.details.examFiles[index]
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'

      if (file.pixeonUid) {
        a.href = import.meta.env.VITE_URL_PIXEON + file.pixeonUid
        a.click()
      } else if (file.extension.includes('pdf')) {
        const route = this.$router.resolve({ name: 'examesPdf', query: { id: file.examFileId, isLaudo: 'false' } })
        window.open(route.href, '_blank')
      } else {
        this.$root.$emit('showLoading')
        a.href = await this.getExamFileById(file.examFileId, 'octet/stream')
        a.download = `${this.$i18n.t('document')}.${file.extension}`
        window.open(a.href, '_self').close()
        this.$root.$emit('hideLoading')
      }
    },
    async onSelectAttachment () {
      this.fileList = this.details.examFiles
      try {
        if (this.attachment.target.files.length) {
          this.$root.$emit('showLoading')
          const file = this.attachment.target.files[0]
          const fileInfo = await this.getProperties(file)
          const signedFile = await this.getSignedUrl(this.details.examId, fileInfo.md5, fileInfo.extension, fileInfo.binaryFileType)
          signedFile.extension = fileInfo.extension.toLocaleLowerCase()
          await this.uploadFile(file, signedFile.data.linkStorage)
          const newFile = {}
          newFile.title = `DOCUMENTO ${this.fileList.length}`
          if (signedFile.extension) {
            newFile.title += ` (${signedFile.extension})`
          }
          newFile.examFileId = signedFile.data.fileId
          newFile.extension = signedFile.extension
          this.fileList.push(newFile)
          this.attachment = ''

          await this.SetSuccessUploadStatus(signedFile.data.fileId)
        } else {
          this.attachment = ''
        }
      } catch (e) {
        this.$root.$emit('toast', { message: this.$i18n.t('error.fail_to_send_file'), type: 'error' })
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
