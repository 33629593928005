import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getQueueFirstItem () {
      const url = '/core/Physician/Exams/Queue/GetItem'
      return this.getClient().get(url, {}, true)
    },
    getExamFile (examId, type = 'application/pdf') {
      return this.requestGetBlob(`/core/Physician/Exams/${examId}/ExamFile`, type)
    },
    getExamFileById (fileId, type = 'application/pdf') {
      return this.requestGetBlob(`/core/Administrator/Exams/File?examFileId=${fileId}`, type)
    },
    getExamFileDownloadSignedUrl (fileId) {
      return this.requestGet(`/core/File/${fileId}`)
    },
    examRepeat (examId, reason, reasonsCodes) {
      return new Promise(async (resolve) => {
        const res = await this.requestPut('/core/Physician/Exams/RepeatExam', {
          examId,
          reason,
          reasonsCodes
        })

        resolve(res.data)
      })
    },
    sendReport (laudo) {
      return new Promise(async (resolve) => {
        const res = await this.requestPost('/core/Physician/Exams/Laudar/InsertMedicalReport', laudo)
        resolve(res.data)
      })
    },
    refreshReportTime (examId) {
      return new Promise(async (resolve) => {
        const res = await this.requestPut('/core/Physician/Exams/UpdateDateLasUpdatetExam', { examId })
        resolve(res.data)
      })
    },
    hasMoreExams () {
      return this.requestGet('/core/Physician/Exams/Queue/HasNext')
    }
  }
}

export default mixin
