
import LocalStorageMixin from '@/shared/mixins/local-storage.mixin'
import Auth from '@/shared/mixins/auth.mixin'
import WebChatVollMixin from '@/shared/mixins/WebChatVoll.mixin'
import AuthService from '@/shared/services/auth.service'

export default {
  name: 'medico-validate',
  mixins: [
    LocalStorageMixin,
    Auth,
    WebChatVollMixin
  ],
  data: () => ({
    certValidate: {},
    urlParams: '',
    showDisable: false,
    AuthService: AuthService()
  }),
  created () {
    if (process.env.NODE_ENV !== 'testing') {
      this.startWebChatVoll()
    }
  },
  async mounted () {
    this.urlParams = (new URL(window.location)).searchParams
    if (this.urlParams.get('code')) {
      this.sendQrCode()
    }
    this.certValidate = await this.getValidateCertificate()
    this.redirect()
  },
  computed: {
    casesOfError () {
      if (!this.certValidate.usePtmSigner) {
        return
      }
      if (!this.certValidate.notUsePtmSigner && !this.certValidate.cert) {
        return {
          message: 'Ops! Parece que todas suas fontes exigem certificado digital e seu certificado está com algum erro ou pode estar revogado. Caso deseje, entre em contato com o time operações pelo chat no canto inferior direito da tela.',
          action: ''
        }
      }
      if (!this.certValidate.notUsePtmSigner && !this.certValidate.token) {
        return {
          message: 'Ops! Parece que todas suas fontes exigem certificado digital e seu token precisa ser validado.',
          action: 'validateQRCodeButton'
        }
      }
      if (this.certValidate.notUsePtmSigner && !this.certValidate.cert) {
        return {
          message: 'Ops! Parece que algumas de suas fontes exigem certificado digital e seu certificado está com algum erro ou pode estar revogado. Caso deseje, entre em contato com o time operações pelo chat no canto inferior direito da tela.',
          action: 'disableSourceButton'
        }
      }
      if (this.certValidate.notUsePtmSigner && !this.certValidate.token) {
        return {
          message: 'Ops! Parece que algumas de suas fontes exigem certificado digital e seu token expirou. Você pode desabilitar as fontes temporariamente para laudar nas demais ou validar o seu QrCode.',
          action: 'multiplesOptions'
        }
      }
    }
  },
  methods: {
    redirect () {
      if (this.certValidate.usePtmSigner && this.certValidate.cert && this.certValidate.token) {
        sessionStorage.setItem('isValidatedPhysician', 'true')
        this.AuthService.getOnlyReports() ? this.$router.push('/medico/laudar') : this.$router.push('/medico/')
      } else if (!this.certValidate.usePtmSigner) {
        sessionStorage.setItem('isValidatedPhysician', 'true')
        this.AuthService.getOnlyReports() ? this.$router.push('/medico/laudar') : this.$router.push('/medico/')
      } else if (this.certValidate.usePtmSigner && this.certValidate.cert && !this.certValidate.token && !this.certValidate.notUsePtmSigner) {
        this.validateQRCode()
      }
    },

    validateQRCode () {
      window.location.href = this.certValidate.qrCodeUrl
    },
    disableSource () {
      this.disablePhysicianSource()
      sessionStorage.setItem('isValidatedPhysician', 'true')
      this.$router.push('/medico')
    },
    sendQrCode () {
      if (this.urlParams.get('code')) {
        this.postSendQrCode(this.urlParams.get('code'))
      }
    },
    logout () {
      this.logoutUser()
    }
  }
}
