import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getInformationsByExamId (examId) {
      const url = '/core/Exams/Information'
      return this.requestGet(`${url}?ExamId=${examId}`)
    },
    async sendInformation (message) {
      const url = '/core/Exams/Information'
      const res = await this.requestPost(url, message)
      return res.data.data
    }
  }
}

export default mixin
